<template>
  <div class="floorCommodityManagement">
    <ds-header title="楼层商品管理"></ds-header>
    <div>
      <div style="margin-bottom: 10px">
        <a-select
          v-model="searchParams"
          class="searchInput"
          placeholder="请选择项目"
          @change="projectChange"

        >
          <a-select-option v-for="(item, index) in projectList" :key="item.id">
            {{ item.project_name }}
          </a-select-option>
        </a-select>
      </div>
      <a-select
          :default-value="floorId"
          style="width: 200px; margin-right: 14px"
          @change="handleChange"
          v-if="this.floor.length > 8"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
      >
        <a-select-option v-for="item in floor" :value="item.id" :key="item.id">
          {{ item.floorName }}
        </a-select-option>
      </a-select>
      <a-radio-group :value="defaultSelected" @change="handleSizeChange" v-if="this.floor.length <= 8">
        <a-radio-button v-for="item in floor" :value="item.id" :key="item.id">
          {{ item.floorName }}
        </a-radio-button>
      </a-radio-group>
    </div>
    <div style="padding-top: 15px">
      <a-table
          :rowKey="(record, index) => index"
          :columns="table.columns"
          :data-source="table.dataInfo"
          :pagination="table.pagination.total ? table.pagination : false"
          @change="pageChange"
          bordered
          :scroll="{ x: 1500 }"
      >
        <span slot="productNo" slot-scope="text, record" class="action">
          <a-tooltip>
            <template slot="title">
              {{ record.productNo }}
            </template>
            <span class="field fixedWidth">{{ record.productNo }}</span>
          </a-tooltip>
        </span>

        <span slot="productName" slot-scope="text, record" class="action">
          <a-tooltip>
            <template slot="title">
              {{ record.productName }}
            </template>
            <span class="field">{{ record.productName }}</span>
          </a-tooltip>
        </span>

        <span slot="action" slot-scope="text, record" class="action">
          <a
            href="javascript:;"
            @click="view(text, record)"
            >查看</a
          >
          <a
            href="javascript:;"
            :disabled="record.floorProductState == true"
            :class="{ inputClass: record.floorProductState == true }"
            @click="edit(text, record)"
            v-if="
              $checkButtonPermissions($buttonPermissionsConfig.integralMallManagement.floorCommodityManagement.edit)
            "
          >编辑</a
          >
          <a
              href="javascript:;"
              @click="enable(text, record)"
              v-if="
              $checkButtonPermissions($buttonPermissionsConfig.integralMallManagement.floorCommodityManagement.enable)
            "
          >{{ record.floorProductState ? "禁用" : "启用" }}</a
          >
          <a
              href="javascript:;"
              :disabled="record.floorProductState == true"
              :class="{ inputClass: record.floorProductState == true }"
              @click="delet(text, record)"
              v-if="
              $checkButtonPermissions($buttonPermissionsConfig.integralMallManagement.floorCommodityManagement.delete)
            "
          >删除</a
          >
        </span>
      </a-table>
    </div>
    <a-modal width="700px" v-model="editVisible" :destroyOnClose="true" :title="editVisibleTitle" :maskClosable="false" @cancel="handleCancel" @ok="handleOk">
      <a-form-model :label-col="{ span: 7 }" :wrapper-col="{ span: 15 }" ref="ruleForm" :model="form" :rules="rules">
        <a-form-model-item>
          <label slot="label"><span class="labelFont">*</span>兑换类型</label>
          <a-radio-group
            :disabled="disabled"
            v-model="form.exchangeType"
          >
            <a-radio :value="0">正常商品</a-radio>
            <a-radio :value="1">活动商品</a-radio>
          </a-radio-group>
        </a-form-model-item>

        <a-form-model-item label="关联有效期" prop="effectiveTime">
          <a-range-picker
            :disabled="disabled"
            @change="onChange"
            :disabledDate="disabledDate"
            :value="[
              form.effectiveTime && moment(form.effectiveTime, 'YYYY-MM-DD'),
              form.failureTime && moment(form.failureTime, 'YYYY-MM-DD'),
            ]"
          />
        </a-form-model-item>

        <a-form-model-item label="可兑换时间段" prop="exchangeStartTime" v-if="form.exchangeType === 1">
          <a-range-picker
            :disabled="disabled"
            @change="onChangeChange"
            :disabledDate="disabledDateExChange"
            :value="[
              form.exchangeStartTime && moment(form.exchangeStartTime, 'YYYY-MM-DD'),
              form.exchangeEndTime && moment(form.exchangeEndTime, 'YYYY-MM-DD'),
            ]"
          />
        </a-form-model-item>

        <a-form-model-item>
          <label slot="label"><span class="labelFont">*</span>兑换限制</label>
          <a-radio-group
            :disabled="disabled"
            v-model="form.exchangeLimit"
          >
            <a-radio :value="0">无限制</a-radio>
            <a-radio :value="1">每日可兑换时段限制</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item v-if="form.exchangeLimit === 1">
          <label slot="label"><span class="labelFont">*</span>每日可兑换时间段</label>
          <a-form-model-item prop="dayExchangeStartTime" :style="{ display: 'inline-block' }">
            <a-time-picker v-model="form.dayExchangeStartTime" valueFormat="HH:mm:ss" :disabled="disabled"/>
          </a-form-model-item>
          <a-form-model-item prop="dayExchangeEndTime" :style="{ display: 'inline-block' }">
            <a-time-picker style="margin-left: 10px;" v-model="form.dayExchangeEndTime" valueFormat="HH:mm:ss" :disabled="disabled"/>
          </a-form-model-item>
        </a-form-model-item>
        <a-form-model-item prop="dayStock" v-if="form.exchangeLimit === 1" label="每日兑换库存数量">
          <a-input :disabled="disabled" style="width: 60px;" v-model.trim="form.dayStock" :maxLength="4"></a-input>
          <span style="padding-left: 10px;">个</span>
        </a-form-model-item>
        <a-form-model-item>
          <label slot="label"><span class="labelFont">*</span>单人限购数量</label>
          <a-radio-group
            :disabled="disabled"
            v-model="form.singleExchangeLimit"
          >
            <a-radio :style="radioStyle" :value="0">
              天&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <a-input :disabled="disabled" style="width: 50px;margin-left:1px;" v-model.trim="form.singleExchangeLimitDay" :maxLength="4"></a-input>
              <span style="padding-left: 10px;">次</span>
            </a-radio>
            <a-radio :style="radioStyle" :value="1">
              周&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <a-input :disabled="disabled" style="width: 50px;margin-left:1px;" v-model.trim="form.singleExchangeLimitWeek" :maxLength="4"></a-input>
              <span style="padding-left: 10px;">次</span>
            </a-radio>
            <a-radio :style="radioStyle" :value="2">
              全周期&nbsp;&nbsp;&nbsp;
              <a-input :disabled="disabled" style="width: 50px;" v-model.trim="form.singleExchangeLimitAll" :maxLength="4"></a-input>
              <span style="padding-left: 10px;">次</span>
            </a-radio>
          </a-radio-group>
        </a-form-model-item>

        <a-form-model-item v-if="productStatus == 0">
          <label slot="label"><span class="labelFont">*</span>核销方式</label>
          <a-radio-group
            :disabled="disabled"
            v-model="form.writeOffType"
          >
            <a-radio :value="0">固定时间</a-radio>
            <a-radio :value="1">相对时间</a-radio>
          </a-radio-group>
        </a-form-model-item>

        <a-form-model-item label="核销时段" prop="writeOffStartTime" v-if="form.writeOffType === 0 && productStatus == 0" key="writeOffStartTime">
          <a-range-picker
            :disabled="disabled"
            @change="onChangeWriteOff"
            style="width: 400px"
            :value="[
              form.writeOffStartTime && moment(form.writeOffStartTime, 'YYYY-MM-DD HH:mm:ss'),
              form.writeOffEndTime && moment(form.writeOffEndTime, 'YYYY-MM-DD HH:mm:ss'),
            ]"
            :disabled-date="disabledDate"
            show-time
            format="YYYY-MM-DD HH:mm:ss"
          />
        </a-form-model-item>
        <a-form-model-item v-if="productStatus === 0" label="核销前短信提醒">
          <a-checkbox-group
            v-model="form.notifyTime"
            :options="options"
            :disabled="disabled"
          />
        </a-form-model-item>
        <a-form-model-item label="核销周期" prop="writeOffDay" v-if="form.writeOffType === 1 && productStatus == 0" key="writeOffDay">
          <a-input :disabled="disabled" style="width: 70px;" v-model.trim="form.writeOffDay" :maxLength="4"></a-input>
          <span style="padding-left: 10px;">天之内可核销(自兑换时间开始后)</span>
        </a-form-model-item>

        <a-form-model-item label="商品排序" prop="productSort">
          <a-input :disabled="disabled" placeholder="请输入" v-model.trim="form.productSort" :maxLength="4"></a-input>
        </a-form-model-item>


        <!-- <a-form-model-item label="单人限购数量" prop="astrictQuantity">
          <a-input placeholder="请输入" v-model.trim="form.astrictQuantity"></a-input>
        </a-form-model-item> -->

        <a-form-item label="状态" :required="true">
          <a-select v-model="form.floorProductState" placeholder="请选择" :disabled="disabled">
            <a-select-option v-for="item in state" :value="item.id" :key="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form-model>
    </a-modal>
    <ConfirmBoxModule ref="confirmBoxModule"></ConfirmBoxModule>
  </div>
</template>

<script>
import {
  getFloor,
  getFloorProductList,
  editFloorProduct,
  deleteFloorProduct,
  disableFloorProduct,
  floorProductGetById,
} from "@/api/floorCommodityManage";
import ConfirmBoxModule from "@/components/confirmBoxModule";
import { DatePicker } from "element-ui";
import ConfirmConfig from "@/utils/confirmConfig";
import moment from "moment";
import debounce from "lodash/debounce";
import { projectName } from "../../../api/pointsManagement";
import {mapState} from "vuex";
export default {
  name: "floorCommodityManagement",
  computed: {
    ...mapState({
      projectList: (state) => {
        let projects =  state.common.projectList;
        projects.unshift({id: '', project_name: '全部'})
        return projects
      },
    }),
  },
  components: {
    ConfirmBoxModule,
    DatePicker,
  },
  data() {
    //校验时间
    let checkTime = (rule, value, callback) => {
      if (this.form.dayExchangeEndTime > this.form.dayExchangeStartTime) {
        return callback();
      }
      // 不合法
      callback(new Error("结束时间需大于开始时间"));
    };
    return {
      options: [
        { label: '2小时', value: 2 },
        { label: '12小时', value: 12 },
        { label: '36小时', value: 36 },
      ],
      // // 项目列表
      // projectList: [],
      searchParams: '',
      state: [
        {
          name: "启用",
          id: 1,
        },
        {
          name: "禁用",
          id: 0,
        },
      ],
      floor: [
        {
          name: "i友周边",
          id: 1,
        },
        {
          name: "热门商品",
          id: 2,
        },
        {
          name: "楼层X",
          id: 3,
        },
      ],
      // 楼层id
      floorId: "",
      // 顶部tab
      defaultSelected: "",
      // 编辑弹框
      editVisible: false,

      // 编辑框内数据
      form: {
        // 当前条id
        id: "",
        // 生效时间段
        effective: [],
        // 生效时间
        effectiveTime: "",
        // 失效时间
        failureTime: "",
        // 商品排序
        productSort: "",
        // 单人限购数量
        // astrictQuantity: "",
        // 楼层中状态
        floorProductState: "",
        // 商品类型
        exchangeType: "",
        // 兑换限制
        exchangeLimit: "",
        // 兑换开始时间
        exchangeStartTime: "",
        // 兑换结束时间
        exchangeEndTime: "",
        // 每日可兑换开始时间
        dayExchangeStartTime: "",
        // 每日可兑换结束时间
        dayExchangeEndTime: "",
        // 每日兑换库存
        dayStock: "",
        // 单人限购类型
        singleExchangeLimit: "",
        // 核销方式
        writeOffType: "",
        // 核销开始时间
        writeOffStartTime: "",
        // 核销结束时间
        writeOffEndTime: "",
        // 核销相对时间
        writeOffDay: "",
        // 单人限购类型天
        singleExchangeLimitDay: "",
        // 单人限购类型周
        singleExchangeLimitWeek: "",
        // 单人限购类型全周期
        singleExchangeLimitAll: "",
        // 核销短信提示时段
        notifyTime: []
      },
      radioStyle: {
        display: 'block',
        height: '40px',
        lineHeight: '40px',
      },
      productStatus: "",
      disabled: false,
      editVisibleTitle: "",
      rules: {
        effectiveTime: [{ required: true, trigger: 'blur', message: "请选择关联有效期"}],
        exchangeStartTime: [{ required: true, trigger: 'blur', message: "请选择可兑换时间段"}],
        dayExchangeStartTime: [{ required: true, trigger: 'change', message: "请选择开始时间"}],
        dayExchangeEndTime: [
          { required: true, trigger: 'change', message: "请选择结束时间"},
          { validator: checkTime, trigger: "change" },
        ],
        dayStock: [{ required: true, trigger: 'blur', pattern: new RegExp(/^(0|\+?[1-9][0-9]*)$/, "g"), message: "请输入每日兑换库存数量, 只能输入数字"}],
        writeOffStartTime: [{ required: true, trigger: 'blur', message: "请选择核销时间段"}],
        writeOffDay: [{ required: true, trigger: 'blur', pattern: new RegExp(/^(0|\+?[1-9][0-9]*)$/, "g"), message: "请输入核销周期，只能输入数字"}],
        productSort: [
          { required: true,  message: "请输入商品排序", trigger: 'blur'},
          { required: true,  message: "只能输入数字", pattern: new RegExp(/^(0|\+?[1-9][0-9]*)$/, "g")}
        ],
      },
      table: {
        dataInfo: [],
        columns: [
          {
            title: "排序",
            dataIndex: "productSort",
            key: "productSort",
            width: "100px",
          },
          {
            title: "商品名称",
            dataIndex: "productName",
            key: "productName",
            scopedSlots: { customRender: "productName" },
            width:'100px'
          },
          {
            title: "商品编号",
            dataIndex: "productNo",
            key: "productNo",
            scopedSlots: { customRender: "productNo" },
            width: 150,
          },
          {
            title: "商品类型",
            dataIndex: "productType",
            key: "productType",
            customRender(text) {
              return text === 0 ? "实物" : text === 1 ? "羊毛券" : "第三方串码";
            },
          },
          {
            title: "归属项目",
            dataIndex: "projectName",
            key: "projectName",
            width: "200px"
          },
          {
            title: "商品价格（元）",
            dataIndex: "productPrice",
            key: "productPrice",
          },
          {
            title: "所需分值",
            dataIndex: "productIntegral",
            key: "productIntegral",
          },
          {
            title: "兑换类型",
            dataIndex: "exchangeType",
            key: "exchangeType",
            customRender(text) {
              return text == 0 ? "正常商品" : text == 1 ? "活动商品" : "-";
            },
          },
          {
            title: "关联有效期",
            dataIndex: "effectiveTime",
            key: "effectiveTime",
            customRender(text, record) {
              if (record.effectiveTime !== null) {
                return (
                    moment(record.effectiveTime).format("YYYY-MM-DD") +
                    " 00:00:00" +
                    "—" +
                    moment(record.failureTime).format("YYYY-MM-DD") +
                    " 23:59:59"
                );
              }
            },
          },
          {
            title: "可兑换时段",
            dataIndex: "exchangeStartTime",
            key: "exchangeStartTime",
            customRender(text, record) {
              if (record.exchangeStartTime !== null) {
                return (
                  moment(record.exchangeStartTime).format("YYYY-MM-DD") +
                  "—" +
                  moment(record.exchangeEndTime).format("YYYY-MM-DD")
                );
              }
            },
          },
          {
            title: "兑换限制",
            dataIndex: "exchangeLimit",
            key: "exchangeLimit",
            customRender(text, record) {
              if (record.effectiveTime) {
                return text == 0 ? "无限制" : text == 1 ? "每日可兑换时段限制" : "-";
              } else {
                return "-"
              }
            },
          },
          {
            title: "每日可兑换时间段",
            dataIndex: "dayExchangeStartTime",
            key: "dayExchangeStartTime",
            customRender(text, record) {
              if (record.dayExchangeStartTime !== null && record.exchangeLimit == 1) {
                return (
                  record.dayExchangeStartTime +
                  "—" +
                  record.dayExchangeEndTime
                );
              }
            },
          },
          {
            title: "单人限购数量",
            dataIndex: "singleExchangeLimit",
            key: "singleExchangeLimit",
            customRender(text, record) {
              if (record.singleExchangeLimit === 0 && record.singleExchangeSum) {
                return (
                  record.singleExchangeSum+"次/天"
                );
              } else if (record.singleExchangeLimit === 1 && record.singleExchangeSum) {
                return (
                  record.singleExchangeSum+"次/周"
                );
              } else if (record.singleExchangeLimit === 2 && record.singleExchangeSum) {
                return (
                  record.singleExchangeSum+"次/全周期"
                );
              } else {
                return "-";
              }
            },
          },
          {
            title: "状态",
            dataIndex: "floorProductState",
            key: "floorProductState",
            customRender(text) {
              return text ? "启用" : !text ? "禁用" : "-";
            },
          },
        ],
        //分页数据
        pagination: {
          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          },
        },
      },
      // 列表操作按钮权限配置
      actions: [
        this.$buttonPermissionsConfig.integralMallManagement.floorCommodityManagement.edit,
        this.$buttonPermissionsConfig.integralMallManagement.floorCommodityManagement.enable,
        this.$buttonPermissionsConfig.integralMallManagement.floorCommodityManagement.delete,
      ],
    };
  },
  created() {
    this.$checkTableActionIsShow(this.actions) && this.table.columns.push(this.$backTableAction({ width: "200px" }));
    this.getFloor();
    // this.getProjectList();
  },
  methods: {
    // 获取项目列表
    // getProjectList() {
    //   projectName().then(res => {
    //     if (res.code === '200') {
    //       this.projectList = res.data.items;
    //       this.projectList.unshift({id: null,project_name: "全部"})
    //     }
    //   })
    // },
    // 项目change事件
    projectChange () {
      this.table.pagination.current = 1;
      this.getFloorProductList();
    },
    moment,
    // 选择时间
    onChange(date, dateString) {
      console.log(date, dateString);
      this.form.effective = dateString;
      this.form.effectiveTime = dateString[0];
      this.form.failureTime = dateString[1];
    },
    onChangeChange (date, dateString) {
      this.form.exchangeStartTime = dateString[0];
      this.form.exchangeEndTime = dateString[1];
    },
    // 固定时间
    onChangeWriteOff(date, dateString) {
      this.form.writeOffStartTime = dateString[0];
      this.form.writeOffEndTime = dateString[1];
    },
    // 根据楼层id获取列表
    async getFloorProductList() {
      const res = await getFloorProductList(
          this.floorId,
          this.table.pagination.current,
          this.table.pagination.pageSize,
          this.searchParams || null
      );
      if (res.code === "200") {
        this.table.dataInfo = res.data.productList;
        this.table.pagination.total = res.data.total;
      }
    },
    // 限制不可以选择今天之前的时间
    disabledDate(current) {
      return current && current < moment().subtract(1, "days").endOf("day");
    },
    // 限制兑换时间
    disabledDateExChange(current) {
      return !(current && current >= new Date(this.form.effectiveTime+" 00:00:00") && current <= new Date(this.form.failureTime+" 23:59:59"))
    },
    // 获取楼层
    async getFloor() {
      const res = await getFloor();
      if (res.code === "200") {
        this.floor = res.data;
        this.floorId = this.floor[0].id;
        this.defaultSelected = this.floor[0].id;
        console.log(this.floorId, "999");
      }
      this.getFloorProductList();
    },
    // 获取楼层商品详情
    async floorProductGetById(id) {
      const res = await floorProductGetById(id);
      if (res.code === "200") {
        if (res.data.singleExchangeLimit === null) {
          res.data.singleExchangeLimit = 0;
        }
        if (res.data.singleExchangeLimit === 0) {
          res.data.singleExchangeLimitDay = res.data.singleExchangeSum;
          res.data.singleExchangeLimitWeek = "";
          res.data.singleExchangeLimitAll = "";
        }
        if (res.data.singleExchangeLimit === 1) {
          res.data.singleExchangeLimitDay = "";
          res.data.singleExchangeLimitWeek = res.data.singleExchangeSum;
          res.data.singleExchangeLimitAll = "";
        }
        if (res.data.singleExchangeLimit === 2) {
          res.data.singleExchangeLimitDay = "";
          res.data.singleExchangeLimitWeek = "";
          res.data.singleExchangeLimitAll = res.data.singleExchangeSum;
        }
        this.form = {
          ...res.data,
        };
        this.form.id = id;
        if (res.data.effectiveTime !== null && res.data.failureTime !== null) {
          this.form.effectiveTime = moment(this.form.effectiveTime).format("YYYY-MM-DD");
          this.form.failureTime = moment(this.form.failureTime).format("YYYY-MM-DD");
          this.form.effective = [this.form.effectiveTime, this.form.failureTime];
        } else {
          this.form.effectiveTime = undefined;
          this.form.failureTime = undefined;
          this.form.effective = [];
        }
        if (res.data.exchangeStartTime !== null && res.data.exchangeEndTime !== null) {
          this.form.exchangeStartTime = moment(this.form.exchangeStartTime).format("YYYY-MM-DD");
          this.form.exchangeEndTime = moment(this.form.exchangeEndTime).format("YYYY-MM-DD");
        } else {
          this.form.exchangeStartTime = undefined;
          this.form.exchangeEndTime = undefined;
        }
        if (res.data.writeOffStartTime !== null && res.data.writeOffEndTime !== null) {
          this.form.writeOffStartTime = moment(this.form.writeOffStartTime).format("YYYY-MM-DD HH:mm:ss");
          this.form.writeOffEndTime = moment(this.form.writeOffEndTime).format("YYYY-MM-DD HH:mm:ss");
        } else {
          this.form.writeOffStartTime = undefined;
          this.form.writeOffEndTime = undefined;
        }
        this.form.floorProductState = res.data.floorProductState == true ? 1 : 0;
        // 短信提示时间段
        if (res.data.notifyTime) {
          let notifyTime = res.data.notifyTime.split(',')
          this.form.notifyTime = notifyTime.map(item => {
            return parseInt(item)
          })
        } else {
          this.form.notifyTime = []
        }
        this.editVisible = true;
      }
    },
    // 顶部下拉框切换数据
    handleChange(value) {
      this.floorId = value;
      this.getFloorProductList();
      console.log(`selected ${value}`);
    },
    // 关闭编辑弹窗
    handleCancel() {
      this.$refs.ruleForm.resetFields();
      this.editVisible = false;
    },
    // 编辑提交
    handleOk: debounce(function () {
      if (this.editVisibleTitle === "查看") {
        this.editVisible = false;
        return;
      }
      let obj = {
        effective: undefined,
        // astrictQuantity: parseInt(this.form.astrictQuantity),
        productSort: parseInt(this.form.productSort),
        floorProductState: this.form.floorProductState == 1 ? true : false,
        effectiveTime: this.form.effectiveTime + " 00:00:00",
        failureTime: this.form.failureTime + " 23:59:59",
        id: this.form.id,
        exchangeType: this.form.exchangeType,
        exchangeStartTime: this.form.exchangeStartTime + " 00:00:00",
        exchangeEndTime: this.form.exchangeEndTime + " 23:59:59",
        exchangeLimit: this.form.exchangeLimit,
        dayExchangeStartTime: this.form.dayExchangeStartTime,
        dayExchangeEndTime: this.form.dayExchangeEndTime,
        dayStock: parseInt(this.form.dayStock),
        singleExchangeLimit: this.form.singleExchangeLimit,
        singleExchangeSum: "",
        writeOffType: this.form.writeOffType,
        writeOffStartTime: this.form.writeOffStartTime,
        writeOffEndTime: this.form.writeOffEndTime,
        writeOffDay: parseInt(this.form.writeOffDay),
        // 短信提示时间段
        notifyTime: this.form.notifyTime?.length > 0 ? this.form.notifyTime.toString() : ""
      };
      if (this.form.singleExchangeLimit == 0) {
        if(!this.form.singleExchangeLimitDay) {
          this.$message.error("请输入单人限购数量！");
          return;
        }
        obj.singleExchangeSum = parseInt(this.form.singleExchangeLimitDay);
      }
      if (this.form.singleExchangeLimit == 1) {
        if(!this.form.singleExchangeLimitWeek) {
          this.$message.error("请输入单人限购数量！");
          return;
        }
        obj.singleExchangeSum = parseInt(this.form.singleExchangeLimitWeek);
      }
      if (this.form.singleExchangeLimit == 2) {
        if(!this.form.singleExchangeLimitAll) {
          this.$message.error("请输入单人限购数量！");
          return;
        }
        obj.singleExchangeSum = parseInt(this.form.singleExchangeLimitAll);
      }

      if (this.form.effectiveTime == "" || this.form.effectiveTime == null) {
        obj.effectiveTime = null;
        obj.failureTime = null;
      }

      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const res = await editFloorProduct(obj);
          if (res.code == "200") {
            this.$message.success("编辑成功");

            this.form.effective = "";
          } else {
            this.$message.error(res.msg);
          }
          this.getFloorProductList();
          this.$refs.ruleForm.resetFields();
          this.editVisible = false;
        }
      });
    }, 500),
    // 点击顶部按钮切换不同模块
    handleSizeChange(e) {
      this.defaultSelected = e.target.value;
      this.floorId = e.target.value;
      this.table.pagination.current = 1;
      this.getFloorProductList();
    },
    // 启用禁用
    async disableFloorProduct(id, text) {
      const res = await disableFloorProduct(id);
      if (res.code == "200") {
        this.$message.success(`${text ? "禁用" : "启用"}成功`);
        this.getFloorProductList();
      } else {
        this.$message.error(res.msg);
      }
    },
    // 启用禁用
    async enable(text, record) {
      if(record.effectiveTime === null) {
        this.$message.error("还未关联商品！");
        return;
      }
      const confirmData = {
        title: "提示",
        content: `是否确认${record.floorProductState ? "禁用" : "启用"}？`,
        type: ConfirmConfig.CONFIRM_TYPE_WARNING,
      };
      await this.$refs.confirmBoxModule.show(confirmData);
      this.disableFloorProduct(record.id, record.floorProductState);
    },
    // 编辑
    edit(text, record) {
      this.form.id = record.id;
      this.productStatus = record.productType;
      this.disabled = false;
      this.editVisibleTitle = "编辑";
      this.floorProductGetById(this.form.id);
    },
    // 查看
    view(text, record) {
      this.form.id = record.id;
      this.productStatus = record.productType;
      this.disabled = true;
      this.editVisibleTitle = "查看";
      this.floorProductGetById(this.form.id);
    },
    // 删除楼层
    async deleteFloorProduct(id) {
      const res = await deleteFloorProduct(id);
      if (res.code == "200") {
        this.$message.success("删除成功");
        this.getFloorProductList();
      } else {
        this.$message.error(res.msg);
      }
    },
    // 删除
    async delet(record) {
      const confirmData = {
        title: "提示",
        content: "是否确认删除关系？",
        type: ConfirmConfig.CONFIRM_TYPE_WARNING,
      };
      await this.$refs.confirmBoxModule.show(confirmData);
      this.deleteFloorProduct(record.id);
    },
    //分页操作
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.getFloorProductList();
    },
  },
};
</script>

<style lang="scss" scoped>
.action a {
  color: #1890ff;
  margin-right: 15px;
}
.action a:hover {
  color: #40a9ff;
}
.field {
  font-size: 12px;
  color: #333;
  display: inline-block;
  height: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 80px;
}
.fixedWidth {
  max-width: 150px !important;
}
.inputClass {
  color: #bdbdbd !important;
}
.labelFont {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
}
.searchInput {
  width: 200px;
}
</style>
