import { fetchApi } from "@/utils/axios";
import http from "@/utils/axios";
import URL from "@/api/urlConfig"
import store from '@/store'

// 获取楼层
export const getFloor = () => {
  return fetchApi(URL.GET_FLOOR);
}

// 获取商品列表
export const getFloorProductList = (id, pageNum, pageSize, projectId) => {
  return fetchApi(URL.GET_FLOORPRODUCT_LIST + '/'+ id + '/' + pageNum + '/' + pageSize + "/" + projectId + '/', {}, 'get', 'params', true)
}

// 楼层商品详情
export const floorProductGetById = (id) => {
  return fetchApi(URL.FLOORPRODUCT_GETBYID + '/'+ id)
}

// 编辑楼层商品
export const editFloorProduct = (data) => {
  return fetchApi(URL.EDIT_FLOORPROFUCT, data, 'PUT')
}

// 删除楼层商品
export const deleteFloorProduct = (id) => {
  return fetchApi(URL.DELETE_FLOORPRODUCT + '/' + id, null, 'DELETE')
}

// 启用禁用楼层商品
export const disableFloorProduct = (id) => {
  return fetchApi(URL.DISABLE_FLOORPRODUCT + '/' + id, null, 'PUT')
}
